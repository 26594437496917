import * as React from "react"
import classnames from "classnames"
import PropTypes from "prop-types"
import { Col, Container, Row } from "react-bootstrap"
import Link from "/src/components/ui/base/Link"
import useScrollPos from "../../../../../hooks/useScrollPos"
import * as styles from "./index.module.scss"
import { useTranslation } from "react-i18next"
import { LogoJsonLd } from "gatsby-plugin-next-seo"

const Navbar = ({ whiteHeader }) => {
  const [isScrolled, setScrolled] = React.useState(false)
  const [isActive, setActive] = React.useState(false)
  const [isSubMenu, setSubMenu] = React.useState(false)
  const { t, i18n: l } = useTranslation()

  let pathname = typeof window !== "undefined" ? window.location.href : ""
  const isPPC = pathname.includes("gclid")

  useScrollPos(offset => {
    setScrolled(offset > 100)
  }, [])

  return (
    <>
      <header
        className={classnames({
          [styles.header]: true,
          [styles.headerScrolled]: isScrolled,
        })}
      >
        <LogoJsonLd
          logo="https://www.weshoot.it/media/images/logo/WeShoot-logo-extended-black.svg"
          url="https://www.weshoot.it/"
        />
        <Container fluid="xl">
          <Row>
            <Col lg={3} sm={3} xs={3}>
              <div className={styles.logo_home}>
                <Link to="/" title="WeShoot | Viaggi Fotografici">
                  WeShoot | Viaggi Fotografici
                </Link>
              </div>
            </Col>
            <nav lg={5} sm={3} xs={3}>
              <a
                className={classnames({
                  [styles.toggle_switch]: true,
                  [styles.active]: isActive,
                })}
                onClick={() => {
                  setActive(!isActive)
                }}
              >
                <span>Menu mobile</span>
              </a>
              <div
                className={classnames({
                  [styles.main_menu]: true,
                  [styles.show]: isActive,
                })}
              >
                {isActive && (
                  <>
                    <div className={styles.header_menu}>
                      <img
                        src={
                          "/media/images/logo/WeShoot-logo-extended-black.svg"
                        }
                        width="160"
                        height="34"
                        alt="WeShoot | Viaggi Fotografici"
                      />
                    </div>
                    <a
                      className={styles.open_close}
                      onClick={() => {
                        setActive(false)
                      }}
                    >
                      <i className="icon-cancel" />
                    </a>
                  </>
                )}
                <ul>
                  <li>
                    <a
                      className={styles.show_sub_menu}
                      onClick={() => {
                        setSubMenu(!isSubMenu)
                      }}
                    >
                      {t("ui.layout.header.photoTours")}
                      <i className="icon-angle-down" />
                    </a>
                    <ul
                      className={classnames({
                        [styles.show_normal]: isSubMenu,
                      })}
                    >
                      <li>
                        <Link
                          to="/viaggi-fotografici/"
                          onClick={() => {
                            setActive(false)
                          }}
                        >
                          <h2> {t("ui.layout.header.allTours")}</h2>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/viaggi-fotografici/destinazioni/"
                          onClick={() => {
                            setActive(false)
                          }}
                        >
                          <h2> {t("ui.layout.header.toursDestinations")} </h2>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/viaggi-fotografici/calendario/"
                          onClick={() => {
                            setActive(false)
                          }}
                        >
                          <h2> {t("ui.layout.header.toursCalendar")} </h2>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/viaggi-fotografici/collezioni/"
                          onClick={() => {
                            setActive(false)
                          }}
                        >
                          <h2> {t("ui.layout.header.toursCollections")}</h2>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/viaggi-fotografici/storie/"
                          onClick={() => {
                            setActive(false)
                          }}
                        >
                          <h2> {t("ui.layout.header.toursStories")}</h2>
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <a
                      className={styles.show_sub_menu}
                      onClick={() => {
                        setSubMenu(!isSubMenu)
                      }}
                    >
                      {t("Accademia fotografia")}
                      <i className="icon-angle-down" />
                    </a>
                    <ul
                      className={classnames({
                        [styles.show_normal]: isSubMenu,
                      })}
                    >
                      {/*<li>*/}
                      {/*  <Link*/}
                      {/*    to="/accademia-di-fotografia/"*/}
                      {/*    onClick={() => {*/}
                      {/*      setActive(false)*/}
                      {/*    }}*/}
                      {/*  >*/}
                      {/*    <h2> {t("Accademia di Fotografia")}</h2>*/}
                      {/*  </Link>*/}
                      {/*</li>*/}
                      <li>
                        <Link
                          to="/corsi-di-fotografia/"
                          onClick={() => {
                            setActive(false)
                          }}
                        >
                          <h2> {t("Corsi di fotografia")} </h2>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="https://accademia.weshoot.it/wp-login"
                          onClick={() => {
                            setActive(false)
                          }}
                        >
                          {t("Accesso membri")}
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <Link
                      to="https://www.weshoot.it/blog"
                      className={styles.show_sub_menu}
                      onClick={() => {
                        setActive(false)
                      }}
                    >
                      {t("ui.layout.header.blog")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/recensioni/"
                      className={styles.show_sub_menu}
                      onClick={() => {
                        setActive(false)
                      }}
                    >
                      {t("ui.layout.header.reviews")}
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </Row>
        </Container>
      </header>
    </>
  )
}

Navbar.propTypes = {
  siteTitle: PropTypes.string,
}

Navbar.defaultProps = {
  siteTitle: ``,
}

export default Navbar
